import '../styles/components/footer.scss'

import React from 'react'

import { useFooter } from '../hooks/cms/use-footer'
import { LanguageSelector } from './language-selector'
const getFilePath = file => `/assets/files/${file.split('/').pop()}`

export const Footer: React.FC = () => {
  const {
    address,
    logo,
    designedBy,
    rightsReserved,
    companyRegistryDetails,
    infoContact,
    socials,
  } = useFooter('footer')

  return (
    <div className="footer">
      <div className="footer__top">
        <img className="footer__top-logo" src={getFilePath(logo)} />
        <div className="footer__top-address">{address}</div>
        <div className="footer__top-registry">
          {companyRegistryDetails.map(({ registryDetail }, index) => (
            <span key={index}>{registryDetail}</span>
          ))}
        </div>
        <div className="footer__top-contact">
          <span>{infoContact.label}</span>
          <a href={`mailto:${infoContact.medium}`}>{infoContact.medium}</a>
        </div>
        <div className="footer__top-socials">
          {socials.map(({ title, link }, index) => (
            <div key={index}>
              <a href={link} target="_blank" rel="noreferrer">
                {title}
              </a>
            </div>
          ))}
        </div>
        <div className="footer__top-language">
          <LanguageSelector variant="foot" />
        </div>
      </div>

      <div className="footer__bottom">
        {<div className="footer__bottom-rights">{rightsReserved}</div>}

        {<div className="footer__bottom-designed-by">{designedBy}</div>}
      </div>
    </div>
  )
}
