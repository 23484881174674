import '../styles/components/icon-button.scss'

import React from 'react'

import { classNameMap, joinClassNames } from '../utils/tsx'

export interface IconButtonProps {
  href?: string
  variant?: 'primary' | 'secondary'
  onClick?: () => void
  className?: string
  disabled?: boolean
  [x: string]: any
}

export const IconButton: React.FC<IconButtonProps> = ({
  children,
  className,
  disabled,
  href,
  onClick,
  variant = 'primary',
  ...rest
}) =>
  React.createElement(
    href ? 'a' : 'button',
    {
      className: joinClassNames(
        'icon-button',
        `icon-button--variant-${variant}`,
        className,
        classNameMap({ 'icon-button--disabled': disabled })
      ),
      onClick,
      disabled,
      href,
      ...rest,
    },
    children
  )
