import { useCmsContext } from '../../contexts/cms'

type PageData<TPage extends keyof cms.CMSData['pages']> =
  cms.CMSData['pages'][TPage]

export const usePageData = <TPage extends keyof cms.CMSData['pages']>(
  page: TPage
): PageData<TPage> => {
  const { cmsData } = useCmsContext()

  return cmsData.pages[page]
}
