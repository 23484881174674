import { useCmsContext } from '../../contexts/cms'

type FooterData<TFooter extends keyof cms.CMSData['common-elements']> =
  cms.CMSData['common-elements'][TFooter]

export const useFooter = <TFooter extends keyof cms.CMSData['common-elements']>(
  footer: TFooter
): FooterData<TFooter> => {
  const { cmsData } = useCmsContext()

  return cmsData['common-elements'][footer]
}
