import React from 'react'

export const Logo: React.FC = () => (
  <svg viewBox="0 0 116 17" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.7451 12.2683V7.55848C23.7451 6.77612 24.3828 6.14335 25.1701 6.14335H32.4939C33.2812 6.14335 33.9189 6.77637 33.9189 7.55848V9.14515H37.2251V6.28319L33.792 2.87061H23.8871L20.4434 6.29393V13.5683L23.8599 16.9654H37.2251V13.6834H25.1698C24.3826 13.6834 23.7451 13.0522 23.7451 12.2683Z" />
    <path d="M72.9553 2.87256H63.0531L59.6084 6.29288V13.5688L63.0265 16.9659H70.1885V13.6836H64.3351C63.5459 13.6836 62.9106 13.0524 62.9106 12.2685V7.55868C62.9106 6.77633 63.5459 6.14355 64.3351 6.14355H71.6582C72.4472 6.14355 73.0839 6.77658 73.0839 7.55868V16.9656H76.3897V6.28339L72.9553 2.87256Z" />
    <path d="M93.5646 2.87256H83.664L80.2178 6.29288V16.9659H83.5182V7.55868C83.5182 6.77633 84.1552 6.14355 84.944 6.14355H92.2671C93.0543 6.14355 93.6933 6.77658 93.6933 7.55868V16.9656H96.9968V6.28339L93.5646 2.87256Z" />
    <path d="M13.3424 2.87256H3.08237L0 5.93355V6.14355H12.0521C12.8375 6.14355 13.4755 6.77658 13.4755 7.55868V12.2685C13.4755 13.0524 12.8375 13.6836 12.0521 13.6836H4.72645C3.93921 13.6836 3.30148 13.0524 3.30148 12.2685V9.14086H0V13.5281L3.45903 16.9659H13.3245L16.782 13.5313V6.28814L13.3424 2.87256Z" />
    <path d="M48.0823 13.6776C47.2958 13.6776 46.6573 13.046 46.6573 12.2622L46.6591 6.537C46.6591 6.27255 46.8742 6.05954 47.1385 6.05954H54.3794L56.1856 4.06084V2.86796H47.1385C46.8742 2.86796 46.6591 2.65171 46.6591 2.38901L46.6573 0H44.3833L43.3581 1.02033V2.38901C43.3581 2.65171 43.143 2.86796 42.8784 2.86796H40.2968H40.2554V6.05954H40.2983H42.8799C43.1445 6.05954 43.3596 6.27255 43.3596 6.537V13.5437L46.792 16.9593H56.2336V13.6776H48.0823Z" />
    <path d="M107.74 13.6776C106.953 13.6776 106.314 13.046 106.314 12.2622L106.317 6.537C106.317 6.27255 106.532 6.05954 106.796 6.05954H114.036L115.842 4.06084V2.86796H106.796C106.532 2.86796 106.317 2.65171 106.317 2.38901L106.314 0H104.042L103.013 1.02033L103.015 2.38901C103.015 2.65171 102.8 2.86796 102.534 2.86796H99.9533H99.9111V6.05954H99.9533H102.537C102.8 6.05954 103.015 6.27255 103.015 6.537L103.013 13.5437L106.449 16.9593H115.891V13.6776H107.74Z" />
  </svg>
)
