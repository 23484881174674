import '../styles/components/button.scss'

import { Link } from 'gatsby'
import type { ReactNode, MouseEvent } from 'react'
import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

import { useHrefMaker } from '../hooks/use-href-maker'
import { classNameMap, joinClassNames } from '../utils/tsx'

export interface ButtonProps {
  href?: string
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary'
  icon?: ReactNode
  onClick?: (e: MouseEvent) => void
  className?: string
  disabled?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  href,
  children,
  variant = 'primary',
  icon,
  onClick,
  className,
  disabled,
}) =>
  React.createElement(
    href ? 'a' : 'button',
    {
      className: joinClassNames(
        'button',
        `button--variant-${variant}`,
        classNameMap({ 'button--disabled': disabled }),
        className
      ),
      onClick,
      disabled,
      tabIndex: disabled ? -1 : undefined,
    },
    <>
      <span className="button__content">{children}</span>
      {icon ? <span className="button__icon">{icon}</span> : null}
    </>
  )

export const CaseStudiesButton = () => {
  const makeHref = useHrefMaker()
  return (
    <Link
      to={makeHref('/case-studies')}
      className="button button--variant-quinary"
    >
      CASE STUDY
      <BsArrowRight />
    </Link>
  )
}
