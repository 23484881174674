import '../styles/components/drawer.scss'

import { Link } from 'gatsby'
import type { Dispatch, SetStateAction } from 'react'
import { useMemo } from 'react'
import { memo } from 'react'
import React, { useCallback, useState } from 'react'
import { BsArrowRight, BsChevronRight } from 'react-icons/bs'
import { VscClose } from 'react-icons/vsc'

import { useNavigation } from '../hooks/cms/use-navigation'
import { useHrefMaker } from '../hooks/use-href-maker'
import { noop } from '../utils/fp'
import { classNameMap } from '../utils/tsx'
import { Button } from './button'
import { IconButton } from './icon-button'
import { LanguageSelector } from './language-selector'
import { Logo } from './logo'

export interface DrawerProps {
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
}

export const useDrawerHandlers = () => {
  const [open, setOpen] = useState(false)

  const openDrawer = useCallback(() => setOpen(true), [])
  const closeDrawer = useCallback(() => setOpen(false), [])
  const toggleDrawer = useCallback(() => setOpen(value => !value), [])

  return { openDrawer, closeDrawer, toggleDrawer, props: { open, setOpen } }
}

export const Drawer = memo<DrawerProps>(({ open = false, setOpen = noop }) => {
  const overlay = useMemo(
    () => (
      <div className="drawer__overlay" onClick={() => setOpen(false)}></div>
    ),
    [setOpen]
  )
  const makeHref = useHrefMaker()
  const { items, supportButton } = useNavigation('header-navigation')

  const content = useMemo(
    () => (
      <div className="drawer__content">
        <nav className="header__mobile-navigation container">
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                <Link to={makeHref(`/${item.page}`)}>
                  <span>{item.label}</span>
                  <BsChevronRight />
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="header__mobile-addons container">
          {supportButton && (
            <a href={supportButton.link} target={'_blank'} rel="noreferrer">
              <Button variant="secondary" icon={<BsArrowRight />}>
                {supportButton.label}
              </Button>
            </a>
          )}

          <LanguageSelector />
        </div>
      </div>
    ),
    [items, makeHref, supportButton]
  )

  return (
    <div className={classNameMap({ drawer: true, 'drawer--open': open })}>
      {overlay}

      <div className="drawer__container">
        <div className="drawer__header container">
          <div className="drawer__logo-container">
            <Link to={makeHref('/')}>
              <Logo />
            </Link>
          </div>

          <IconButton
            onClick={() => setOpen(false)}
            disabled={!open}
            className="drawer__close-button"
            variant="secondary"
          >
            <VscClose />
          </IconButton>
        </div>

        {content}
      </div>
    </div>
  )
})
