import React, { createContext, useContext, useMemo } from 'react'

import { Language } from '../../config/i18n'

export const CmsContext = createContext<{
  entity: any
  cmsData: cms.CMSData
  i18n: {
    language: Language
    availableLanguages: Language[]
    defaultLanguage: Language
  }
}>({
  entity: {} as any,
  cmsData: {} as any,
  i18n: {
    language: Language.EN,
    availableLanguages: [],
    defaultLanguage: Language.EN,
  },
})

interface CmsContextProviderProps {
  entity: any
  cmsData: cms.CMSData
  i18n: {
    language: Language
    availableLanguages: Language[]
    defaultLanguage: Language
  }
}
export const CmsContextProvider: React.FC<CmsContextProviderProps> = ({
  cmsData,
  i18n,
  entity,
  children,
}) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[CMS data]`, cmsData)
    console.log(`[CMS entity]`, entity)
    console.log(`[i18n]`, i18n)
  }

  return (
    <CmsContext.Provider
      value={useMemo(
        () => ({ entity, cmsData, i18n }),
        [cmsData, entity, i18n]
      )}
    >
      {children}
    </CmsContext.Provider>
  )
}

export const useCmsContext = () => useContext(CmsContext)
