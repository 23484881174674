import { useCallback } from 'react'

import type { Language } from '../../config/i18n'
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../../config/i18n'
import { useCmsContext } from '../contexts/cms'

export const useHrefMaker = () => {
  const { i18n } = useCmsContext()

  return useCallback(
    (pathname: string, language = i18n.language) => {
      const prefixedPathname = pathname.split('/')
      const resolvedPathname = AVAILABLE_LANGUAGES.includes(
        prefixedPathname[1] as Language
      )
        ? prefixedPathname.slice(2)
        : prefixedPathname.slice(1)

      const prefix = language === DEFAULT_LANGUAGE ? '/' : `/${language}/`

      return `${prefix}${resolvedPathname.join('/')}`
    },
    [i18n]
  )
}
