import '../../styles/components/icon.scss'

import React from 'react'

export const Menu: React.FC = () => (
  <svg
    className="icon icon--menu"
    viewBox="0 0 18 9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1H17.5" />
    <path d="M0 8H17.5" />
  </svg>
)
