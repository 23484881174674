import { useCmsContext } from '../../contexts/cms'

type NavigationData<TNav extends keyof cms.CMSData['common-elements']> =
  cms.CMSData['common-elements'][TNav]

export const useNavigation = <
  TNav extends keyof cms.CMSData['common-elements']
>(
  nav: TNav
): NavigationData<TNav> => {
  const { cmsData } = useCmsContext()

  return cmsData['common-elements'][nav]
}
