import '../styles/components/language-selector.scss'

import { Link } from 'gatsby'
import React from 'react'
import { BsChevronDown } from 'react-icons/bs'
import { GrLanguage } from 'react-icons/gr'

import { useCmsContext } from '../contexts/cms'
import { useHrefMaker } from '../hooks/use-href-maker'
import { win } from '../utils/monads'

interface LanguageSelectorProps {
  variant?: 'nav' | 'foot'
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  variant = 'nav',
}) => {
  const { i18n } = useCmsContext()
  const makeHref = useHrefMaker()

  return (
    <div className={`language-selector ${variant}`}>
      <div className="language-selector__button">
        <GrLanguage />
        <span>{i18n.language}</span>
        <BsChevronDown />
      </div>
      <div className="language-selector__list">
        <div className="language-selector__list-items">
          {i18n.availableLanguages
            .filter(lang => lang !== i18n.language)
            .map(lang => (
              <Link key={lang} to={makeHref(win.location.pathname, lang)}>
                {lang}
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}
