import type { CreatePageArgs } from 'gatsby'
import React from 'react'

import type { Language } from '../../config/i18n'
import { CmsContextProvider } from '../contexts/cms'

interface PageProps<TEntity = any> extends CreatePageArgs {
  pageContext: {
    entity: TEntity
    cmsData: cms.CMSData
    i18n: {
      language: Language
      availableLanguages: Language[]
      defaultLanguage: Language
    }
  }
}

export const PageComponentFactory = function <TEntity = any>(
  Component: React.FC
): React.FC<PageProps<TEntity>> {
  return ({ pageContext: { cmsData, i18n, entity } }) => {
    return (
      <CmsContextProvider cmsData={cmsData} i18n={i18n} entity={entity}>
        <Component />
      </CmsContextProvider>
    )
  }
}
