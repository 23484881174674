import { graphql, useStaticQuery } from 'gatsby'
import type { FixedObject, FluidObject } from 'gatsby-image'
import { useMemo } from 'react'

export interface Image {
  fluid: FluidObject & { originalName: string }
  fixed: FixedObject & { originalName: string }
}

export const useImages = () => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed {
              originalName
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  `)

  return useMemo(() => {
    const list: Image[] = data.allImageSharp.edges.map(edge => ({
      fixed: edge.node.fixed,
      fluid: edge.node.fluid,
    }))
    const table = new Map(list.map(img => [img.fixed.originalName, img]))

    const getImage = (path: string) => {
      if (!path) {
        return
      }

      const parts = path.split('/')

      return table.get(parts[parts.length - 1])
    }

    return { list, table, getImage }
  }, [data])
}
